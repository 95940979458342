import Axios from 'lib/Axios';
import { APIResponse } from 'types/APIResponse';
import { Brand, BrandParameters } from 'types/Brand';

export const _GetBrandsList = (params?: BrandParameters) =>
  Axios.get<APIResponse<Brand[]>>('/lookups/brands', { params }).then((res) => res.data);

export const _GetBrandDetails = (brand_id: string) =>
  Axios.get<Brand>(`/lookups/brands/${brand_id}`).then((res) => res.data);

// export const _GetBrandCategory = (brand_category: string) =>
//   Axios.get<APIResponse<Brand[]>>(`/lookups/brands`).then((res) => res.data);
