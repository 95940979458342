import Link from '@Components/common/Link';
import styled from 'styled-components';
import { mq } from 'stylesheets/utils';
export const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 14px 20px;
  /* margin: 0 auto 14px auto; */
  width: 174px;
  height: 143px;
  background-color: ${({ theme }) => theme.colors.lightLavender};
  border: 1px solid ${({ theme }) => theme.colors.lightenPurple};
  border-radius: 4px;
  ${mq(576)} {
    width: 46%;
  }
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.fontsizes.body14};
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.black};
`;

export const CategoryImage = styled.div`
  margin-left: auto;
  max-width: 90px;
  min-width: 90px;
  max-height: 80px;
  min-height: 80px;
  display: flex;
  justify-content: flex-end;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
