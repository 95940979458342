import { CommonButton } from '@Components/common/Button';
import styled from 'styled-components';
import { mq } from 'stylesheets/utils';

export const Card = styled.form`
  padding: 35px 40px 30px 40px;
  border: 1px solid ${({ theme }) => theme.colors.auth.lightPurple};
  background-color: ${({ theme }) => theme.colors.filterCardBg};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  ${mq(1200)} {
    padding: 16px;
    max-width: unset;
  }
  ${mq(992)} {
    padding: 16px;
    margin-bottom: 16px;
  }
  ${mq(768)} {
    margin-bottom: 20px;
    max-width: unset;
  }
`;

export const Title = styled.h6`
  font-size: ${({ theme }) => theme.fontsizes.h6};
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 32px;
`;

export const Button = styled(CommonButton)`
  width: 100%;
`;
