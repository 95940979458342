import Link from '@Components/common/Link';
import { useAuth } from '@Contexts/AuthContext/Auth.context';
import { _GetOffersOfTheDay } from '@Services/Offers';
import { K_OffersOfTheDay } from '@Utils/ReactQuery.keys';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Fragment, useCallback } from 'react';
import { useQuery } from 'react-query';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import URLParse from 'url-parse';
import { Div, TimerSectionWrapper } from './OffersOfTheDay.styled';

type Props = {
  inListing?: boolean;
};

export default function TimerSection({ inListing }: Props) {
  const { isTokenValid } = useAuth();
  const { t } = useTranslation('Landing');
  const { locale } = useRouter();
  const { data } = useQuery(
    K_OffersOfTheDay({
      locale,
    }),
    () => _GetOffersOfTheDay(),
    {
      enabled: isTokenValid,
    }
  );

  const urlProps = useCallback((url: string) => {
    const parsed = URLParse(url);
    const isSameSite = parsed.host === window.location.host;
    return {
      as: isSameSite ? Link : 'a',
      href: isSameSite ? parsed.pathname : url,
      ...(isSameSite
        ? {}
        : {
            target: '_blank',
            rel: 'noreferrer noopener',
          }),
    };
  }, []);

  return !Boolean(data?.results.length) ? (
    <Fragment></Fragment>
  ) : (
    <TimerSectionWrapper inListing={inListing}>
      <Swiper
        dir="ltr"
        spaceBetween={0}
        modules={[Pagination]}
        pagination={{
          clickable: true,
        }}
      >
        {data?.results.map((offer, i) => (
          <SwiperSlide key={offer.id + offer.redirect_url}>
            <Div {...urlProps(offer.redirect_url)}>
              <Image src={offer.image} alt="Offer of the Day" layout="fill" objectFit="contain" priority={i === 0} />
            </Div>
          </SwiperSlide>
        ))}
      </Swiper>
    </TimerSectionWrapper>
  );
}
