import Image from 'next/image';
import { PartCategory } from 'types/Categories';
import { Card, CategoryImage, Title } from './Categorycard.styled';
interface Props {
  data: PartCategory;
}
export default function CategoryCard({ data }: Props) {
  return (
    <Card href={`/products?category_id=${data.id}`}>
      <Title>{data.name}</Title>
      <CategoryImage>
        <Image src={data.image} width={90} height={80} alt={data.name} objectFit="contain" priority={true} />
      </CategoryImage>
    </Card>
  );
}
