import Image from 'next/image';
import { TagImgWrapper, TagItem } from './Tag.styled';

interface Props {
  name?: string;
  isActive?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  img?: string;
  isWhite?: boolean;
}

const Tag: React.FC<Props> = ({ isActive, name = '', img, isWhite, ...props }) => {
  return (
    <TagItem variant="default" isBigLabel={Boolean(img)} isActive={isActive} isWhite={isWhite} {...props}>
      {img && (
        <TagImgWrapper>
          <Image layout="fill" objectFit="contain" src={img} alt={name} />
        </TagImgWrapper>
      )}
      {name}
    </TagItem>
  );
};
export default Tag;
