import React from 'react';
import ContentLoader from 'react-content-loader';

const CategoryCardLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={173}
    height={143}
    viewBox="0 0 173 143"
    backgroundColor="#ddd"
    foregroundColor="#ededed"
    style={{
      border: '1px solid #ddd',
    }}
    {...props}
  >
    <rect x="15" y="20" rx="0" ry="0" width="68" height="19" />
    <rect x="77" y="72" rx="0" ry="0" width="77" height="53" />
  </ContentLoader>
);

export default CategoryCardLoader;
