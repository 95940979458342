import Link from '@Components/common/Link';
import styled from 'styled-components';
import { mq } from 'stylesheets/utils';

export const SectionTitle = styled.h5`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.black};
  font-size: 2.8rem;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
  margin-bottom: 38px;
`;
export const Arrow = styled.div`
  max-width: 34px;
  min-width: 34px;
  max-height: 36px;
  min-height: 36px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) /*rtl: translateY(-50%) */;
  right: 0px;
  background-color: ${({ theme }) => theme.colors.arrowBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center center;
  cursor: pointer;
  user-select: none;
  &.car-make-next {
    right: auto;
    left: 0px;
    transform: translateY(-50%) /*rtl: translateY(-50%)  rotate(180deg) */;
  }
  &.car-make-prev {
    transform: translateY(-50%) rotate(180deg) /*rtl: translateY(-50%)  rotate(0deg) */;
  }
`;
export const ShowMoreArrow = styled.div`
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(-1, 1) /*rtl: none */;
  margin-left: 16px;
`;

export const ShowMore = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.cartCountColor};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 34px auto;
`;

export const BrandSectionWrapper = styled.div`
  padding: 50px 0 25px 0;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  position: relative;
  ${ShowMore} {
    justify-content: center;
  }
  .swiper {
    ${mq(1200)} {
      max-width: 80%;
    }
    ${mq(768)} {
      max-width: 60%;
    }
    ${mq(576)} {
      max-width: 80%;
    }
  }
  .swiper-slide {
    width: 124px;
    max-width: 124px;
    min-width: 124px;
    max-height: 124px;
    min-height: 124px;
  }
  ${Arrow} {
    right: -40px;
    &.car-make-next {
      right: unset;
      left: -40px;
    }
    ${mq(1200)} {
      right: 20px;
      &.car-make-next {
        right: unset;
        left: 20px;
      }
    }
    ${mq(576)} {
      right: 2px;
      &.car-make-next {
        right: unset;
        left: 2px;
      }
    }
  }
`;

export const CarMakeWrapper = styled(Link)`
  max-width: 124px;
  min-width: 124px;
  max-height: 124px;
  min-height: 124px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  display: block;
  position: relative;
  user-select: none;
`;
export const Inner = styled.div`
  min-height: 124px;
`;
