import { _GetProducts } from '@Services/Products';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import CloseIcon from 'public/icons/close-x.svg';
import Search from 'public/search.svg';
import { ChangeEventHandler, FormEventHandler, memo, useCallback, useState } from 'react';
import { useDebounce } from 'react-use';
import { APIResponse } from 'types/APIResponse';
import { Product } from 'types/Products';
import {
  ClearSearch,
  Input,
  SearchButton,
  SearchInputWrapper,
  SearchResult,
  SearchResultsWrapper,
  Wrapper,
} from './SearchBar.styled';

type Props = {
  searchDefaultValue?: string;
  onSubmitCallback?: (searchQuery: string) => void;
  onClearCllback?: () => void;
};

export default memo(function SearchBar({ searchDefaultValue, onClearCllback, onSubmitCallback }: Props) {
  const { t } = useTranslation('common');
  const { push, query } = useRouter();
  const [searchQuery, setSearchQuery] = useState<string>(searchDefaultValue || '');
  const [searchResults, setSearchResults] = useState<APIResponse<Product[]>>();
  const [isOpen, setIsOpen] = useState(false);
  const [, cancelDebounce] = useDebounce(
    () => {
      if (searchQuery) {
        _GetProducts({
          limit: 6,
          search: searchQuery,
        }).then((data) => {
          setSearchResults(data);
          setIsOpen(true);
        });
      }
    },
    250,
    [searchQuery]
  );

  const handleSubmitSearch: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (searchQuery) {
      push({
        pathname: `/products/search/${searchQuery}`,
      }).then(() => {
        setIsOpen(false);
      });
      if (onSubmitCallback) onSubmitCallback(searchQuery);
    } else {
      setSearchQuery('');
      setIsOpen(false);
      setTimeout(() => {
        setSearchResults(undefined);
      }, 400);
    }
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      e.preventDefault();
      if (e.target.value) {
        setSearchQuery(e.target.value);
      } else {
        setIsOpen(false);
        cancelDebounce();
        setSearchQuery('');
        setTimeout(() => {
          setSearchResults(undefined);
        }, 400);
      }
    },
    [cancelDebounce]
  );

  const handleClear = useCallback(() => {
    setIsOpen(false);
    cancelDebounce();
    setSearchQuery('');
    setTimeout(() => {
      setSearchResults(undefined);
    }, 400);
    if (onClearCllback) onClearCllback();
  }, [cancelDebounce, onClearCllback]);

  return (
    <Wrapper>
      <SearchInputWrapper onSubmit={handleSubmitSearch}>
        <Input name="search" type="text" placeholder={t('search')} value={searchQuery} onChange={handleInputChange} />
        {searchQuery && (
          <ClearSearch type="button" onClick={handleClear}>
            <Image src={CloseIcon} alt="close-icon" aria-hidden="true" objectFit="contain" width={15} height={15} />
          </ClearSearch>
        )}
        <SearchButton>
          <Image src={Search} width={26.26} height={26.26} alt="search icon" />
        </SearchButton>
      </SearchInputWrapper>
      <SearchResultsWrapper dropdownOpen={isOpen}>
        {searchResults?.results.map((result) => (
          <SearchResult key={result.id + '_SEARCH_RESULT'} href={`/products/${result.id}`}>
            {result.name}
          </SearchResult>
        ))}
      </SearchResultsWrapper>
    </Wrapper>
  );
});
