import Link from '@Components/common/Link';
import styled, { css } from 'styled-components';
import { rgba } from 'stylesheets/utils';
export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  max-width: 832px;
  margin: 0 auto;
  z-index: 6;
`;
export const SearchInputWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 4px 0px ${({ theme }) => rgba(theme.colors.black2, 0.16)};
  border: 1px solid #d9dcf1;
  background-color: ${({ theme }) => theme.colors.filterCardBg};
`;
export const ClearSearch = styled.button`
  position: relative;
  min-width: 40px;
  height: 40px;
  background-color: transparent;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black};
  min-width: 65px;
  height: 48px;
`;

export const Input = styled.input`
  appearance: none;
  padding: 0 30px;
  border: none;
  line-height: 1.5;
  width: 100%;
  font-size: ${({ theme }) => theme.fontsizes.h6};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  color: ${({ theme }) => theme.colors.lightPurple};
  background-color: transparent;
  overflow: hidden;
  height: 48px;
`;
export const SearchResultsWrapper = styled.div<{ readonly dropdownOpen?: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.4s ease all;
  transform: translateY(15px);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px ${({ theme }) => rgba(theme.colors.black2, 0.16)};
  background-color: ${({ theme }) => theme.colors.white};
  ${({ dropdownOpen }) =>
    dropdownOpen &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: translateY(7px);
    `}
`;
export const SearchResult = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-left: 1px solid #d9dcf1;
  border-right: 1px solid #d9dcf1;
  color: ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid #d9dcf1;
  transition: 0.2s ease background-color;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightenBlue};
  }
  &:first-of-type {
    border-top: 1px solid #d9dcf1;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
