import Link from '@Components/common/Link';
import styled from 'styled-components';
export const SectionTitle = styled.h5`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.black};
  font-size: 2.8rem;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
`;

export const SectionSubTitle = styled.h6`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
`;

export const CategorySectionWrapper = styled.div`
  padding: 70px 0;
  display: flex;
  flex-direction: column;
`;

export const CategoryList = styled.div`
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;
`;

export const ShowMoreArrow = styled.div`
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(-1, 1) /*rtl: none */;
  margin-left: 16px;
`;

export const ShowMore = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.cartCountColor};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  display: flex;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
`;
