import Image from 'next/image';
import { memo } from 'react';
import { Guarantee } from 'types/Guarantee';
import { Card, CardImage, Title } from './FeatureCard.styled';
interface Props {
  data: Guarantee;
}
export default memo(function FeatureCard({ data }: Props) {
  return (
    <Card>
      <CardImage>
        <Image src={data.img} alt={data.title} width={42} height={42} objectFit="contain" priority={true} />
      </CardImage>
      <Title>{data.title}</Title>
    </Card>
  );
});
