import styled, { css } from 'styled-components';
import { mq } from 'stylesheets/utils';
export const Card = styled.div<{
  readonly withMargin?: boolean;
}>`
  width: 100%;
  height: 90px;
  padding: 4px 16px;
  border: 1px solid ${({ theme }) => theme.colors.auth.lightPurple};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin: 12px 0 0 0;
  ${mq(768)} {
    padding: 10px;
  }
  ${({ withMargin }) =>
    withMargin
      ? css`
          margin: 12px 0 0 0;
        `
      : css``}
`;

export const CardImage = styled.div`
  max-width: 42px;
  min-width: 42px;
  max-height: 42px;
  min-height: 42px;
  margin-right: 20px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.fontsizes.body12};
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.black};
  max-height: ${12 * 1.5 * 3}px;
  flex: 1;
`;
