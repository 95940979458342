import Link from '@Components/common/Link';
import styled from 'styled-components';
import { mq } from 'stylesheets/utils';
export const TimerSectionWrapper = styled.div<{ readonly inListing?: boolean }>`
  min-height: ${({ inListing }) => (inListing ? '290px' : '390px')};
  max-height: ${({ inListing }) => (inListing ? '290px' : '390px')};
  height: ${({ inListing }) => (inListing ? '290px' : '390px')};
  background-color: ${({ theme }) => theme.colors.lightLavender};
  width: 100%;
  .swiper {
    height: 100%;
    .swiper-slide {
      height: 100%;
    }
    .swiper-pagination {
      bottom: 22px;
      display: flex;
      padding-left: 20% /*rtl:ignore */;
      padding-right: 0 /*rtl:ignore */;
    }
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background-color: #9499b9;
      &.swiper-pagination-bullet-active {
        background-color: ${({ theme }) => theme.colors.brandColor};
      }
    }
  }
  ${mq(992)} {
    margin: 0 auto;
  }
  ${mq(768)} {
    width: 100%;
    min-height: unset;
    height: ${({ inListing }) => (inListing ? '220px' : '320px')};
  }
  ${mq(576)} {
    min-height: unset;
    height: ${({ inListing }) => (inListing ? '160px' : '260')};
  }
`;

export const Div = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;

export const SaleWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  ${mq(576)} {
    flex-direction: column;
  }
`;

export const SaleNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  h2 {
    font-size: 7.2rem;
    font-weight: ${({ theme }) => theme.fontWeight.font700};
    color: ${({ theme }) => theme.colors.darkBrandColor};
    line-height: 1;
  }
  h4 {
    font-size: ${({ theme }) => theme.fontsizes.h4};
    font-weight: ${({ theme }) => theme.fontWeight.font700};
    color: ${({ theme }) => theme.colors.darkBrandColor};
    line-height: 1;
    transform: translateY(-10px);
  }
`;

export const SaleTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  h4 {
    font-size: 3.2rem;
    font-weight: ${({ theme }) => theme.fontWeight.font700};
    color: ${({ theme }) => theme.colors.black};
  }
  h6 {
    font-size: 2.2rem;
    font-weight: ${({ theme }) => theme.fontWeight.font700};
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const Timer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
`;

export const TimerTitle = styled.h6`
  font-size: ${({ theme }) => theme.fontsizes.h6};
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 12px;
`;

export const Clock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ClockField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    width: 54px;
    height: 54px;
    line-height: 58px;
    text-align: center;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.darkYellow};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
    font-size: 2.9rem;
    font-weight: ${({ theme }) => theme.fontWeight.font500};
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 8px;
  }
  span {
    font-size: ${({ theme }) => theme.fontsizes.body14};
    font-weight: ${({ theme }) => theme.fontWeight.font400};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const Button = styled(Link)`
  line-height: 48px;
  height: 48px;
  width: 290px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.brandColor};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;
