import { CommonSelect } from '@Components/common/CommonSelect';
import { _GetCarsList, _SaveCarToProfile } from '@Services/Car';
import { AxiosError } from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { Car } from 'types/Car';
import { SelectOption } from 'types/common';
import { Button, Card, Title } from './LandingFilter.styled';

interface FilterCarFormValues {
  car_type?: null | SelectOption;
  car_brand?: null | SelectOption;
  car_model?: null | SelectOption;
  car_year?: null | SelectOption;
  car?: null | SelectOption;
}

type Props = {};
export default function LandingFilter({}: Props) {
  const { t } = useTranslation('common');
  const { push } = useRouter();
  const { t: TLanding } = useTranslation('Landing');
  const { data, isLoading } = useSelector((state: RootState) => state.carSpecs);
  const { setValue, control, watch, handleSubmit } = useForm<FilterCarFormValues>();
  const [carList, setCarList] = useState<null | Car[]>(null);
  const [isLocalLoading, setIsLocalLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const { profileCars: profileCarsModel } = useDispatch<Dispatch>();

  const carTypes = useMemo(
    () =>
      data
        ?.filter((c) => Boolean(c.car_make.length))
        .map((type) => ({
          value: type.id,
          label: type.type,
        })),
    [data]
  );

  const getCarBrands = useCallback(
    (carTypeId) => {
      return data
        ?.find((carType) => carType.id === carTypeId)
        ?.car_make.map((make) => ({
          label: make.name,
          value: make.id,
        }));
    },
    [data]
  );
  const getCarModel = useCallback(
    (carTypeId, carBrandId) => {
      return data
        ?.find((carType) => carType.id === carTypeId)
        ?.car_make.find((carBrand) => carBrand.id === carBrandId)
        ?.car_model.map((model) => ({
          label: model.name,
          value: model.id,
        }));
    },
    [data]
  );
  const getCarYear = useCallback(
    (carTypeId, carBrandId, carModelId) => {
      return data
        ?.find((carType) => carType.id === carTypeId)
        ?.car_make.find((carBrand) => carBrand.id === carBrandId)
        ?.car_model.find((model) => model.id === carModelId)
        ?.available_years.map((year) => ({
          label: year.year,
          value: year.id,
        }));
    },
    [data]
  );

  const getTheCarList = useCallback(async (car_type__id, make__id, model__id, year__id) => {
    try {
      setIsLocalLoading(true);
      const data = await _GetCarsList({
        car_type__id,
        make__id,
        model__id,
        year__id,
      });
      setCarList(data.results);
      setIsLocalLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const submitHandler = useCallback(
    (data: FilterCarFormValues) => {
      setIsFormLoading(true);
      if (data.car) {
        push(`/products/car/${data.car.value}`);
      }
      _SaveCarToProfile(data.car?.value)
        .then(() => {
          profileCarsModel.getProfileCars().then(() => {
            setIsFormLoading(false);
          });
        })
        .catch((err: AxiosError) => {
          console.log(err);
        });
    },
    [profileCarsModel, push]
  );

  return (
    <Card onSubmit={handleSubmit(submitHandler)}>
      <Title>{t('car-data')}</Title>
      <Controller
        name="car_type"
        control={control}
        render={({ field }) => (
          <CommonSelect
            {...field}
            onChange={(value: any) => {
              setValue('car_brand', null);
              setValue('car_model', null);
              setValue('car_year', null);
              field.onChange(value);
            }}
            isSearchable={false}
            isClearable={false}
            options={carTypes}
            placeholder={t('select-car-type')}
            isDisabled={isLoading || !Boolean(data)}
          />
        )}
      />
      <Controller
        name="car_brand"
        control={control}
        render={({ field }) => (
          <CommonSelect
            {...field}
            isSearchable={true}
            isClearable={false}
            options={(watch('car_type') && getCarBrands(watch('car_type')?.value)) || undefined}
            placeholder={t('select-car-brand')}
            isDisabled={isLoading || !Boolean(data) || !Boolean(watch('car_type'))}
            onChange={(value: any) => {
              setValue('car_model', null);
              setValue('car_year', null);
              field.onChange(value);
            }}
          />
        )}
      />
      <Controller
        name="car_model"
        control={control}
        render={({ field }) => (
          <CommonSelect
            {...field}
            isSearchable={true}
            isClearable={false}
            options={
              (watch('car_type') &&
                watch('car_brand') &&
                getCarModel(watch('car_type')?.value, watch('car_brand')?.value)) ||
              undefined
            }
            placeholder={t('select-car-model')}
            isDisabled={isLoading || !Boolean(data) || !Boolean(watch('car_type')) || !Boolean(watch('car_brand'))}
            onChange={(value: any) => {
              setValue('car_year', null);
              field.onChange(value);
            }}
          />
        )}
      />
      <Controller
        name="car_year"
        control={control}
        render={({ field }) => (
          <CommonSelect
            {...field}
            isSearchable={true}
            isClearable={false}
            options={
              (watch('car_type') &&
                watch('car_brand') &&
                watch('car_model') &&
                getCarYear(watch('car_type')?.value, watch('car_brand')?.value, watch('car_model')?.value)) ||
              undefined
            }
            placeholder={t('select-car-year')}
            isDisabled={
              isLoading ||
              !Boolean(data) ||
              !Boolean(watch('car_type')) ||
              !Boolean(watch('car_brand')) ||
              !Boolean(watch('car_model')) ||
              !Boolean(getCarYear(watch('car_type')?.value, watch('car_brand')?.value, watch('car_model')?.value))
            }
            onChange={(value: any) => {
              getTheCarList(
                watch('car_type')?.value,
                watch('car_brand')?.value,
                watch('car_model')?.value,
                (value as SelectOption).value
              );
              return field.onChange(value);
            }}
          />
        )}
      />
      <Controller
        name="car"
        control={control}
        render={({ field }) => (
          <CommonSelect
            {...field}
            isSearchable={true}
            isClearable={false}
            options={
              (watch('car_type') &&
                watch('car_brand') &&
                watch('car_model') &&
                watch('car_type') &&
                Boolean(carList) &&
                carList?.map((car) => ({
                  label: car.transmission,
                  value: car.id,
                }))) ||
              undefined
            }
            placeholder={t('select-car-transmission')}
            isLoading={isLocalLoading}
            isDisabled={
              isLoading ||
              !Boolean(data) ||
              !Boolean(watch('car_type')) ||
              !Boolean(watch('car_brand')) ||
              !Boolean(watch('car_model')) ||
              !Boolean(carList)
            }
          />
        )}
      />
      <Button type="submit" variant="filled-red" disabled={isFormLoading || !watch('car')}>
        {TLanding('search')}
      </Button>
    </Card>
  );
}
