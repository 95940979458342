import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import Logo from 'public/logo.svg';
import NextIcon from 'public/next.svg';
import More from 'public/show-arrow.svg';
import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Arrow,
  BrandSectionWrapper,
  CarMakeWrapper,
  Inner,
  SectionTitle,
  ShowMore,
  ShowMoreArrow,
} from './BrandSection.styled';

type Props = {};

export default function BrandSection({}: Props) {
  const { t } = useTranslation('common');
  const { data } = useSelector((state: RootState) => state.carSpecs);
  const carTypes = useMemo(() => data?.[0]?.car_make, [data]);

  return (
    <BrandSectionWrapper>
      <Container fluid>
        <Row justifyContent="center">
          <Col col={12}>
            <SectionTitle>{t('discover-your-car')}</SectionTitle>
          </Col>
          <Col col={12} lg={8}>
            <Inner>
              {carTypes && (
                <Fragment>
                  <Swiper
                    modules={[Navigation]}
                    dir="ltr"
                    navigation={{
                      nextEl: '.car-make-prev',
                      prevEl: '.car-make-next',
                    }}
                    spaceBetween={16}
                    observer={true}
                    slidesPerView={'auto'}
                    breakpoints={{
                      992: {
                        spaceBetween: 24,
                      },
                    }}
                  >
                    {carTypes.map((carMaker, i) => (
                      <SwiperSlide key={carMaker.id}>
                        <CarMakeWrapper href={`/walkthrough?make__id=${carMaker.id}`}>
                          <Image
                            src={carMaker?.image || Logo}
                            alt={carMaker.name}
                            layout="fill"
                            objectFit="contain"
                            priority={i < 8}
                          />
                        </CarMakeWrapper>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <Arrow className="car-make-next">
                    <Image src={NextIcon} width={9.71} height={17.76} alt="next" priority={true} />
                  </Arrow>
                  <Arrow className="car-make-prev">
                    <Image src={NextIcon} width={9.71} height={17.76} alt="prev" priority={true} />
                  </Arrow>
                </Fragment>
              )}
            </Inner>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row justifyContent="center">
          <Col auto>
            <ShowMore href="/walkthrough">
              {t('show_more_cars')}
              <ShowMoreArrow>
                <Image src={More} width={7.4} height={12} alt="show more arrow" />
              </ShowMoreArrow>
            </ShowMore>
          </Col>
        </Row>
      </Container>
    </BrandSectionWrapper>
  );
}
