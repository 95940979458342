import { CommonButton } from '@Components/common/Button';
import styled, { css } from 'styled-components';

export const TagItem = styled(CommonButton)<{
  readonly isActive?: boolean;
  readonly isBigLabel?: boolean;
  readonly isWhite?: boolean;
}>`
  font-size: ${({ theme }) => theme.fontsizes.body14};
  border-radius: 20px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  background-color: ${({ theme, isWhite }) => (isWhite ? theme.colors.white : theme.colors.lightenGray)};
  border-width: 2px;
  border-color: transparent;
  line-height: 1.215;
  ${({ isBigLabel }) =>
    isBigLabel &&
    css`
      min-height: 57px;
      border-radius: 4px;
      display: flex;
      gap: 10px;
      border-color: #d6ddff;
      background-color: ${({ theme }) => theme.colors.filterCardBg};
      &:hover {
        background-color: #fff;
      }
    `}
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.brandColor};
    `}
  ${({ isWhite }) =>
    isWhite &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.white};
      }
    `}
`;
export const TagImgWrapper = styled.div`
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  position: relative;
`;
