import CategoryCard from '@Components/CategoryCard';
import CategoryCardLoader from '@Components/CategoryCard/CategoryCard.loader';
import { useAuth } from '@Contexts/AuthContext/Auth.context';
import { _GetCategories } from '@Services/Categories';
import { K_Categories } from '@Utils/ReactQuery.keys';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { CategoryList, CategorySectionWrapper, SectionSubTitle, SectionTitle } from './CategorySection.styled';

type Props = {};
export default function CategorySection({}: Props) {
  const { t } = useTranslation('common');
  const { isTokenValid } = useAuth();
  const { locale } = useRouter();
  const { data: categories } = useQuery(
    K_Categories({
      is_top: true,
      locale,
      limit: 10,
    }),
    () =>
      _GetCategories({
        is_top: true,
        limit: 10,
      }),
    {
      enabled: isTokenValid,
    }
  );

  return (
    <CategorySectionWrapper>
      <SectionTitle>{t('car-needs')}</SectionTitle>
      <SectionSubTitle>{t('most-shop')}</SectionSubTitle>
      <Container>
        <Row justifyContent="center">
          <Col col={12} lg={10}>
            <CategoryList>
              {categories
                ? categories.results.map((cat) => (
                    <CategoryCard data={cat} key={cat.id + '_LANDING_CATEGORY_MOST_WANTED'} />
                  ))
                : [...new Array(10)].map((_, i) => (
                    <CategoryCardLoader key={i + '_DUMMY_LANDING_CATEGORY_MOST_WANTED'} />
                  ))}
            </CategoryList>
          </Col>
        </Row>
      </Container>
    </CategorySectionWrapper>
  );
}
